import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Schedule,
  Appointment,
  AppointmentStatusResponse,
  Location,
  Clinic,
  Attachment,
  NewAppointmentStatus,
  NewAppointment,
  AppointmentCollectDocument,
  AppointmentCollectDocumentPresentation,
  AppointmentReschedule,
  FilterAppointmentReschedule,
  Healthplan,
  AppointmentStatusData,
  DocumentData,
  AppointmentReturnPending,
  AppointmentPreview,
  ScheduleLock,
  FilterScheduleLock,
  FilterAppointment,
  AppointmentConfirmation,
  ScheduleLockView,
  ScheduleFilter,
  CreateScheduleLockForm,
  FilterHealthPlan,
  AppointmentRescheduleCreate,
  AppointmentCommunication,
  AppointmentDisplaySettings,
  SharedResource,
  DisplayAppointmentReview,
  FilterAppointmentReview,
  FilterAttachment,
  ImmediateCareAppointmentForm,
  ScheduleSlot,
  FilterScheduleSlots,
  EventsPerDay,
  FilterCalendarEvents,
  AppointmentNotificationOptions,
  AppointmentSettingsInfo,
  AppointmentCheckIn,
  FilterAppointmentCheckIn,
  AppointmentIntegrationStatus,
  FilterAppointmentIntegrationStatus,
} from "@udok/lib/api/models";
import {
  makeItemPost,
  makeItemDelete,
  makeItemsFetch,
  makeItemFetch,
} from "@udok/lib/api/axios";

export type ScheduleListResponse = Array<Schedule>;
export const fetchSchedules = async (token: string, f?: ScheduleFilter) => {
  let filter = {
    ...f,
    limit: 10000,
  };
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/schedule${toQueryString(filter)}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as ScheduleListResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch1");
      return Promise.reject(err);
    });
};

export type ScheduleResponse = Schedule;
export const fetchSchedule = async (token: string, sescID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/schedule/${sescID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch2");
      return Promise.reject(err);
    });
};

export const deleteSchedule = async (token: string, sescID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/schedule/${sescID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch3");
      return Promise.reject(err);
    });
};

export const createSchedule = async (token: string, schedule: Schedule) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/schedule`, schedule, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch4");
      return Promise.reject(new Error(err));
    });
};

export const updateSchedule = async (token: string, schedule: Schedule) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/schedule/${schedule.sescID}`,
      schedule,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch5");
      return Promise.reject(new Error(err));
    });
};

export type AppointmentListResponse = Array<Appointment>;
export const fetchAppointments = async (
  token: string,
  f?: FilterAppointment
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentListResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch7");
      return Promise.reject(err);
    });
};

const makeCanceableAppointments = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, f?: FilterAppointment) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const filter = f ? toQueryString(f) : "";
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/appointments${filter}`, {
        cancelToken: cancel.token,
        headers: {
          Authorization: token,
        },
      })
      .then((r) => {
        return r.data.data.items as AppointmentListResponse;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return undefined;
        }
        const err = deriveErrorMessage(e, "app1");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchCanceableAppointments = makeCanceableAppointments();

export type AppointmentResponse = Appointment;
export const fetchAppointment = async (token: string, appoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as AppointmentResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch8");
      return Promise.reject(err);
    });
};

export type AppointmentEmailRequest = {
  appoID: string;
  sendTarget: "patient" | "doctor";
};

export const sendControlledAppointmentEmail = async (
  token: string,
  mail: AppointmentEmailRequest
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/mailer-controlled-appointment`,
      mail,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch9");
      return Promise.reject(new Error(err));
    });
};

export const sendControlledAppointmentPhone = async (
  token: string,
  mail: AppointmentEmailRequest
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/phone-appointment-invitation`,
      mail,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch25");
      return Promise.reject(new Error(err));
    });
};

export const sendNotificationsPhoneAppointmentConfirmation = async (
  token: string,
  appoID: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/phone-appointment-confirmation`,
      { appoID },
      { headers: { Authorization: token } }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch33");
      return Promise.reject(new Error(err));
    });
};

export const sendEditStatusAppointment = async (
  token: string,
  appoID: string,
  appointmentStatus: NewAppointmentStatus
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/status`,
      appointmentStatus,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentStatusResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch14");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentStatus = async (token: string, appoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}/status`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentStatusData[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch14");
      return Promise.reject(new Error(err));
    });
};

export const confirmAppointment = async (
  token: string,
  appoID: string,
  confirmation: AppointmentConfirmation
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/confirm`,
      confirmation,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentConfirmation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch29");
      return Promise.reject(new Error(err));
    });
};

export function fetchLocation(apiToken: string, locaID: string) {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/location/${locaID}`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return (r?.data?.data?.item as Location) ?? null;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch15");
      return Promise.reject(err);
    });
}

export function fetchclinic(apiToken: string, clinID: string) {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinics/${clinID}`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return (r?.data?.data?.item as Clinic) ?? null;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch16");
      return Promise.reject(err);
    });
}

export const fetchAttachmentsByappoID = (
  token: string,
  appoID: string,
  f?: FilterAttachment
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/attachments${filter}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.items as Attachment[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch15");
      return Promise.reject(err);
    });
};

export const sendEditAppointment = async (
  token: string,
  appoID: string,
  obj: {
    markedAt?: string;
    returnVisitEnabled?: boolean;
    notificationOptions?: AppointmentNotificationOptions;
  }
) => {
  return axios
    .patch(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}`, obj, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Appointment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch17");
      return Promise.reject(err);
    });
};

export const createAppointment = async (
  token: string,
  newAppo: NewAppointment
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/appointments`, newAppo, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Appointment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch19");
      return Promise.reject(new Error(err));
    });
};

export const createImmediateCareAppointment = async (
  token: string,
  data: ImmediateCareAppointmentForm
) =>
  makeItemPost<Appointment, ImmediateCareAppointmentForm>({
    url: `/api/appointments/immediate-care`,
    errLabel: "ICA1",
    token,
    data,
  });

export const fetchAppointmentCollectDocument = async (
  token: string,
  apcdID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents/${apcdID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocumentPresentation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch20");
      return Promise.reject(err);
    });
};

export const createAppointmentReschedule = async (
  token: string,
  newSchedule: AppointmentRescheduleCreate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments-reschedule`,
      newSchedule,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentReschedule;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch21");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentReschedule = async (
  token: string,
  apscID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments-reschedule/${apscID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentReschedule;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch22");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentReschedules = async (
  token: string,
  f?: FilterAppointmentReschedule
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments-reschedule${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentReschedule[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch23");
      return Promise.reject(new Error(err));
    });
};

export const updateAppointmentReschedule = (
  apiToken: string,
  apscID: string
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/appointments-reschedule/${apscID}`,
      {},
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentReschedule;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch25");
      return Promise.reject(new Error(err));
    });
};

export const fetchHealthplans = async (f?: FilterHealthPlan) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/health-plan${filter}`)
    .then((r) => {
      return r.data.data.items as Healthplan[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch24");
      return Promise.reject(err);
    });
};

export type NewCollectDocumentForm = {
  appoID: string;
  codoID: string;
  fileID: string;
};
export async function createCollectDocument(
  token: string,
  collect: NewCollectDocumentForm
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents`,
      collect,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch19");
      return Promise.reject(new Error(err));
    });
}

export async function deleteCollectDocument(apiToken: string, claaID: string) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents/${claaID}`,
      {
        headers: { Authorization: apiToken },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch20");
      return Promise.reject(new Error(err));
    });
}

export type NewDocumentForm = {
  appoID: string;
  resource: string;
  resourceID: string;
  data?: DocumentData;
};

export function createAppointmentAttachment(
  n: NewDocumentForm,
  apiToken: string
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments/${n.appoID}/attachments`,
      n,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Attachment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch22");
      return Promise.reject(new Error(err));
    });
}

export async function fetchAppoCollectDocuments(
  apiToken: string,
  apcdID: string
) {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents/${apcdID}`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocumentPresentation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch23");
      return Promise.reject(new Error(err));
    });
}

export type FormData = {
  doteID: string;
  formSchema: {};
  uiSchema: {};
  printSchema?: {};
  data: {};
  initialData?: String;
};

export const createFormData = (apiToken: string, formData: FormData) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/documents`, formData, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r.data.data.item as DocumentData;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch24");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointsReturnPending = async (
  token: string,
  filter?: { doctID?: string; patiID?: string; clinID?: string }
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-return${f}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentReturnPending[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch24");
      return Promise.reject(new Error(err));
    });
};

export const getAppointmentPreview = async (appoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}/preview`)
    .then((r) => {
      return r.data.data.item as AppointmentPreview;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch25");
      return Promise.reject(new Error(err));
    });
};

export const createScheduleLock = async (
  token: string,
  lock: CreateScheduleLockForm
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/schedule-lock`, lock, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleLockView;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch26");
      return Promise.reject(new Error(err));
    });
};

export const fetchListScheduleLock = async (
  token: string,
  f?: FilterScheduleLock
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/schedule-lock${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as ScheduleLockView[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch27");
      return Promise.reject(new Error(err));
    });
};

export const deleteScheduleLock = async (token: string, scloID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/schedule-lock/${scloID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleLock;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch28");
      return Promise.reject(err);
    });
};

export const fetchHealthplan = async (heplID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/health-plan/${heplID}`)
    .then((r) => {
      return r.data.data.item as Healthplan;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch30");
      return Promise.reject(err);
    });
};

export async function fetchAppointmentCommunication(
  apiToken: string,
  appoID: string
) {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/communication`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCommunication;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch31");
      return Promise.reject(new Error(err));
    });
}

export async function getAppointmentDisplaySettings(appoID: string) {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/display-settings`
    )
    .then((r) => {
      return r.data.data.item as AppointmentDisplaySettings;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch32");
      return Promise.reject(new Error(err));
    });
}

export async function fetchSharedResources(apiToken: string) {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/shared-resources`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r.data.data.items as SharedResource[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch33");
      return Promise.reject(new Error(err));
    });
}

export async function fetchSharedResource(apiToken: string, shreID: string) {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/shared-resources/${shreID}`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r.data.data.item as SharedResource;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch34");
      return Promise.reject(new Error(err));
    });
}

export const sendAppointmentReview = async (token: string, appoID: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/phone-appointment-review`,
      { appoID },
      { headers: { Authorization: token } }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch35");
      return Promise.reject(new Error(err));
    });
};

export const fetchListAppointmentReview = async (
  token: string,
  f?: FilterAppointmentReview
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-review${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as DisplayAppointmentReview[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch36");
      return Promise.reject(new Error(err));
    });
};

export const appointmentDoctorTransfer = async (
  token: string,
  appoID: string,
  doctID: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/transfer`,
      { doctID },
      { headers: { Authorization: token } }
    )
    .then((r) => {
      return r.data.data.item as Appointment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch37");
      return Promise.reject(new Error(err));
    });
};

export const fetchScheduleSlots = (token: string, f: FilterScheduleSlots) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<ScheduleSlot[]>({
    url: `/api/schedule-slots${filter}`,
    errLabel: "slots1",
    token,
  });
};

export const fetchCalendarEvents = (token: string, f: FilterCalendarEvents) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<EventsPerDay[]>({
    url: `/api/calendar-events${filter}`,
    errLabel: "calendar1",
    token,
  });
};

export const getAppointmentSettings = (token: string, appoID: string) =>
  makeItemFetch<AppointmentSettingsInfo>({
    url: `/api/appointments/${appoID}/settings`,
    errLabel: "settings1",
    token,
  });

const makeCanceableCalendarEvents = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, f: FilterCalendarEvents) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const filter = f ? toQueryString(f) : "";
    return axios
      .get<{ data: { items: EventsPerDay[] } }>(
        `${process.env.REACT_APP_BASE_PATH}/api/calendar-events${filter}`,
        {
          cancelToken: cancel.token,
          headers: {
            Authorization: token,
          },
        }
      )
      .then((r) => {
        return r.data.data.items;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return undefined;
        }
        const err = deriveErrorMessage(e, "calendar1-cancel");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchCanceableCalendarEvents = makeCanceableCalendarEvents();

export const createAppointmentCheckIn = async (token: string, appoID: string) =>
  makeItemPost<AppointmentCheckIn, { appoID: string }>({
    token,
    data: { appoID },
    errLabel: "CheckIn1",
    url: `/api/appointment_check_in`,
  });

export const fetchAppointmentCheckIns = (
  token: string,
  f?: FilterAppointmentCheckIn
) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<AppointmentCheckIn[]>({
    url: `/api/appointment_check_in${filter}`,
    errLabel: "CheckIn2",
    token,
  });
};

export const fetchAppointmentCheckIn = (token: string, appoID: string) =>
  makeItemFetch<AppointmentCheckIn>({
    url: `/api/appointment_check_in/${appoID}`,
    errLabel: "CheckIn3",
    token,
  });

export const deleteAppointmentCheckIn = (token: string, appoID: string) =>
  makeItemDelete<AppointmentCheckIn>({
    url: `/api/appointment_check_in/${appoID}`,
    errLabel: "CheckIn4",
    token,
  });

export const fetchAppointmentIntegrationStatus = (
  token: string,
  appoID: string,
  f?: FilterAppointmentIntegrationStatus
) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<AppointmentIntegrationStatus[]>({
    url: `/api/appointments/${appoID}/integration-status${filter}`,
    errLabel: "ais1",
    token,
  });
};
